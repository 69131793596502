(function($){



var $header 		= $('#header'),
	$btnBurger 	  	= $('#menu__btn'),
	$menu 	  		= $('#menu__collapse')
;


$btnBurger.on('click',function(e){
	e.preventDefault();
	var $self = $(this);
	if(!$self.hasClass('is-active')){
		$btnBurger.addClass('is-active');
		$menu.addClass('is-active');
	}else{
		$btnBurger.removeClass('is-active');
		$menu.removeClass('is-active');
	}
});


var $nav_item = $('.nav-item.dropdown');
$nav_item.each(function(index){
	var $link = $(this).find('.dropdown-toggle');
	$link.dropdown();
	$(this).find(".back").on('click',function(e){
		$link.click();
	});
})



var activateAtY = 150,
	scrollClass = 'header--small',
	widthWindow = $(window).width()
;

function activateHeader() {
    if (!$header.hasClass(scrollClass)) {
        $header.addClass(scrollClass);
    }
}

function deactivateHeader() {
    if ($header.hasClass(scrollClass)) {
        $header.removeClass(scrollClass);
    }
}

$(window).scroll(function () {
	if(widthWindow > 991){
	    var iCurScrollPos = $(this).scrollTop();
	    if(iCurScrollPos > activateAtY) {
	        activateHeader();
	    } else {
	        deactivateHeader();
	    }
    }
});

$(window).resize(function () {
	widthWindow = $(window).width();
	if(widthWindow<991){
		$header.removeClass(scrollClass);
	}
});
/*
|
|	
|  SLIDER HOME
|---------------------
|---------------------
|
|*/
var $homeSlider = $('#carousel__section');
if($homeSlider.length > 0){
	var swiper = new Swiper($homeSlider.find('.swiper-container'), {
		slidesPerView: 1,
		pagination: {
	        el: '.swiper-pagination',
	        clickable: true
	    }
	});
}
/*
|
|	
|  SLIDER ACTU
|---------------------
|---------------------
|
|*/
var $sliderActu = $('.slider___actu');
if($sliderActu.length > 0){
	var swiper = new Swiper($sliderActu.find('.swiper-container'), {
		slidesPerView: 3,
     	spaceBetween: 20,
      	navigation: {
	        nextEl: '.swiper-button-next',
	        prevEl: '.swiper-button-prev',
      	},
	  	breakpoints: {
		    640: {
		      slidesPerView: 1,
		      spaceBetween: 10
		    },
		    769: {
		      slidesPerView: 2,
		      spaceBetween: 20
		    }
	  	}
	});
}

/*
|
|	
|  SLIDER Partners
|---------------------
|---------------------
|
|*/
var $sliderPartner = $('.slider___partner');
if($sliderPartner.length > 0){
	var swiper = new Swiper($sliderPartner.find('.swiper-container'), {
		slidesPerView: 6,
     	spaceBetween: 20,
      	navigation: {
	        nextEl: '.swiper-button-next',
	        prevEl: '.swiper-button-prev',
      	},
      	breakpoints: {
		    640: {
		      slidesPerView: 2,
		      spaceBetween: 10
		    },
		    769: {
		      slidesPerView: 4,
		      spaceBetween: 20
		    }
	  	}
});
}




})(jQuery);